import {TweenLite} from 'gsap';
import 'gsap/CSSPlugin';
import utils from '../../static/js/utils/utils';

class SearchBar {
  constructor(options) {
    this.input = options.input;
    this.toggleButton = options.toggleButton;
    this.timeout = options.timeout || 0;
  }

  onFocus() {
    this.toggleButton.classList.add('active');
  }

  onFocusOut() {
    this.toggleButton.classList.remove('active');
  }

  bindEvents() {
    this.input.addEventListener('focus', () => {
      this.onFocus();
    });
    this.input.addEventListener('blur', () => {
      this.onFocusOut();
    });
    this.toggleButton.addEventListener('click', () => {
      setTimeout(() => {
        this.input.focus();
      }, this.timeout);
    });

    this.requestEvent(this.input);
  }

  clearInput(input, button) {
    if (input.parentNode.querySelector('.search-hint-wrapper')) {
      input.parentNode.querySelector('.search-hint-wrapper').classList.remove('_active');
      document.querySelector('.header-collapsed').classList.remove('_darkening');
    }
    input.value = '';
    button.style.display = 'none';
  }

  requestEvent(input) {
    const inputEvents = ['input', 'paste', 'cut'];
    const buttonClear = '<a href="#" class="header-collapsed-searchbar__clear"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="14" height="14"><g transform="translate(-6 -6)"><path fill-rule="nonzero" d="M13,14.4142136 L7.87347584,19.5407377 C7.55527778,19.8589358 7.20172439,19.9296465 6.98959236,19.7175144 L6.28248558,19.0104076 C6.07035354,18.7982756 6.14106422,18.4447222 6.45926227,18.1265242 L11.5857864,13 L6.45926227,7.87347584 C6.14106422,7.55527778 6.07035354,7.20172439 6.28248558,6.98959236 L6.98959236,6.28248558 C7.20172439,6.07035354 7.55527778,6.14106422 7.87347584,6.45926227 L13,11.5857864 L18.1265242,6.45926227 C18.4447222,6.14106422 18.7982756,6.07035354 19.0104076,6.28248558 L19.7175144,6.98959236 C19.9296465,7.20172439 19.8589358,7.55527778 19.5407377,7.87347584 L14.4142136,13 L19.5407377,18.1265242 C19.8589358,18.4447222 19.9296465,18.7982756 19.7175144,19.0104076 L19.0104076,19.7175144 C18.7982756,19.9296465 18.4447222,19.8589358 18.1265242,19.5407377 L13,14.4142136 Z"></path></g></svg></a>';

    inputEvents.forEach(eventName =>
      input.addEventListener(eventName, () => {
        if (!input.parentNode.querySelector('.header-collapsed-searchbar__clear')) {
          input.parentNode.insertAdjacentHTML('beforeend', buttonClear);
          const button = input.parentNode.querySelector('.header-collapsed-searchbar__clear');

          button.addEventListener('click', e => {
            e.preventDefault();
            this.clearInput(input, button);
          });
        }

        if (input.value.length) {
          const headers = new Headers({'X-Requested-With': 'XMLHttpRequest'});
          let url;

          url = `/api/search/?q=${input.value}`;

          fetch(url, {headers})
            .then(response => response.json())
            .then((data) => {
              if (data.length) {
                const hintWrapper = input.parentNode.querySelector('.search-hint-wrapper') || document.createElement('div');
                hintWrapper.classList.add('search-hint-wrapper');
                hintWrapper.innerHTML = '';

                data.forEach((element) => {
                  let htmlContent = `<div><a class="content-search__hint" href="${element.url}"><span class="content-search__hint-title">${element.title}</span> <span class="content-search__hint-text">${element.text}</span></a></div>`;

                  hintWrapper.insertAdjacentHTML('beforeend', htmlContent);
                });

                input.parentNode.appendChild(hintWrapper);
                hintWrapper.classList.add('_active');
                document.querySelector('.header-collapsed').classList.add('_darkening');

                input.parentNode.querySelector('.header-collapsed-searchbar__clear').style.display = 'block';
              }
            });
        } else {
          if (input.parentNode.querySelector('.search-hint-wrapper')) {
            input.parentNode.querySelector('.search-hint-wrapper').innerHTML = '';
            input.parentNode.querySelector('.search-hint-wrapper').classList.remove('_active');
            document.querySelector('.header-collapsed').classList.remove('_darkening');
          }

          input.parentNode.querySelector('.header-collapsed-searchbar__clear').style.display = 'none';
        }
      })
    );
  }
}

class PageHeader {
  constructor(options) {
    this.element = document.querySelector(`.${options.baseClass}`);
    if (!this.element) {
      return;
    }
    this.height = this.element.clientHeight || this.element.offsetHeight;
    this.classes = PageHeader.generateClasses(options.baseClass, options.extraClasses);
    this.delay = options.delay;
    this.shown = false;
    this.shownearch = false;
    this.animating = false;
    this.animatingSearch = false;
    this.speed = 0.3;
    this.fixedHead = this.element.querySelector(`.${this.classes.fixed}`);
    this.searchButtons = this.element.querySelectorAll(`.${this.classes.searchButton}`);

    this.searchBar = new SearchBar({
      input: this.element.querySelector(`.${this.classes.searchBar}`),
      toggleButton: this.element.querySelector(`.${this.classes.searchButton}`),
      timeout: 300,
    });

    this.checkFixedHead();
  }

  static generateClasses(baseClass, extraClasses = {}) {
    const defaultClasses = {
      baseClass,
      searchBar: `${baseClass}__searchbar`,
      fixed: `${baseClass}__fixed`,
      invisibleOnShow: `${baseClass}__invisible-on-show`,
      visibleOnShow: `${baseClass}__visible-on-show`,
      hiddenOnShow: `${baseClass}__hidden-on-show`,
      shownOnShow: `${baseClass}__shown-on-show`,
      toggleButton: 'js-toggle-menu',
      searchButton: 'js-toggle-search',
      searchMobileBar: `${baseClass}__search-mobile`,
      searchCollapsed: '.search-collapsed',
      searchInput: 'js-search-input',
    };

    return Object.assign(defaultClasses, extraClasses);
  }

  fadeOut(item) {
    TweenLite.to(item, this.speed, {
      'pointer-events': 'none',
      opacity: 0,
    });
  }

  fadeIn(item) {
    TweenLite.to(item, this.speed, {
      onComplete() {
        TweenLite.set(item, {
          clearProps: 'pointer-events',
        });
      },
      opacity: 1,
    });
  }

  show(item) {
    TweenLite.to(item, this.speed, {
      opacity: 1,
      onStart() {
        TweenLite.set(item, {
          clearProps: 'display, width',
        });
      },
      position: 'unset',
    });
  }

  hide(item) {
    TweenLite.to(item, this.speed, {
      opacity: 0,
      onComplete() {
        TweenLite.set(item, {
          display: 'none',
          width: 0,
        });
      },
    });

    if (this.element.querySelector('.search-hint-wrapper') && this.element.querySelector('.search-hint-wrapper').classList.contains('_active')) {
      this.element.querySelector('.header-collapsed-searchbar__clear').style.display = 'none';
      this.element.querySelector('.search-hint-wrapper').classList.remove('_active');
      document.querySelector('.header-collapsed').classList.remove('_darkening');
    }
  }

  debounce() {
    if (!utils.isMobile) {
      document.body.style.overflow = this.shown ? 'hidden' : 'auto';
      return;
    }

    const scrollArea = this.element.querySelector('.page-header__collapsed')

    scrollArea.addEventListener('touchstart', (event) => {
      scrollArea.previousClientY = event.touches[0].clientY;
    });

    scrollArea.addEventListener('touchmove', (event) => {
      const currentScrollTop = scrollArea.scrollTop;
      const maxScroll = scrollArea.scrollHeight - scrollArea.offsetHeight;
      const currentClientY = event.touches[0].clientY;
      const deltaY = scrollArea.previousClientY - currentClientY;

      if (
        (currentScrollTop === maxScroll && deltaY > 0) ||
        (currentScrollTop === 0 && deltaY < 0)
      ) {
        event.preventDefault();
      }

      scrollArea.previousClientY = currentClientY;
    });
  }

  animate() {
    if (this.animating) {
      return;
    }

    this.animating = true;
    this.element.classList.toggle(`${this.classes.baseClass}_is_shown`);
    const classToFadeOut = this.shown ? this.classes.visibleOnShow : this.classes.invisibleOnShow;
    const classToFadeIn = this.shown ? this.classes.invisibleOnShow : this.classes.visibleOnShow;
    const classToHide = this.shown ? this.classes.shownOnShow : this.classes.hiddenOnShow;
    const classToShow = this.shown ? this.classes.hiddenOnShow : this.classes.shownOnShow;

    this.fadeOut(document.querySelectorAll(`.${classToFadeOut}`));
    this.hide(document.querySelectorAll(`.${classToHide}`));
    this.fadeIn(document.querySelectorAll(`.${classToFadeIn}`));
    this.show(document.querySelectorAll(`.${classToShow}`));

    this.shown = !this.shown;
    this.debounce();
    this.animating = false;
  }

  animateSeach() {
    if (this.animatingSearch) {
      return;
    }

    this.animatingSearch = true;

    const fixedContainer = document.querySelector(`.${this.classes.fixed}`);

    if (this.shownearch) {
      this.hide(document.querySelectorAll(`.${this.classes.searchMobileBar}`));

      if (fixedContainer) {
        this.hide(fixedContainer.querySelector(`${this.classes.searchCollapsed}`));
      }
    } else {
      this.show(document.querySelectorAll(`.${this.classes.searchMobileBar}`));

      if (fixedContainer) {
        this.show(fixedContainer.querySelector(`${this.classes.searchCollapsed}`));
      }
    }

    this.shownearch = !this.shownearch;
    this.animatingSearch = false;
  }

  checkFixedHead() {
    const speed = .5;
    const head = this.fixedHead;

    if (window.scrollY > this.height) {
      if (head.classList.contains('hidden-lg')) {
        TweenLite.fromTo(head, speed, { top: (this.shownearch) ? '-142px' : '-72px', className: '-=hidden-lg' }, { top: 0 });

        if (this.element.querySelector('.search-hint-wrapper')) {
          const hintWrapper = this.element.querySelector('.search-hint-wrapper');

          if (hintWrapper.classList.contains('_active')) {
            hintWrapper.style.position = 'fixed';
            hintWrapper.style.top = `${head.getBoundingClientRect().height}px`;
          }
        }
      }
    } else if (!head.classList.contains('hidden-lg'))  {
      TweenLite.to(head, speed, { top: (this.shownearch) ? '-142px' : '-72px', onComplete() { head.classList.add('hidden-lg') } });

      if (this.element.querySelector('.search-hint-wrapper')) {
        const hintWrapper = this.element.querySelector('.search-hint-wrapper');

        hintWrapper.style.position = 'absolute';
        hintWrapper.style.top = '100%';

      }
    }
  }

  collapsedFixed() {
    const collapsedHead = this.element.querySelector(`.${this.classes.baseClass}__collapsed`);
    const fixedContainer = document.querySelector(`.${this.classes.fixed}`);

    if (!fixedContainer.classList.contains('hidden-lg')) {
      collapsedHead.classList.toggle(`${this.classes.baseClass}__collapsed_fix`);
    }
  }

  buttonEvent(button, buttons) {
    button.addEventListener('click', (event) => {
      event.preventDefault();
      buttons.forEach(e => e.classList.toggle('active'));

      if (!window.matchMedia('screen and (min-width: 992px)').matches) {
        this.collapsedFixed();

        if (this.shownearch) {
          this.animateSeach();
          this.searchButtons.forEach(el => el.classList.toggle('active'));
        }
      }

      this.animate();
    })
  }

  showSubMenu() {
    const fixedMenu = document.getElementById('header-fixed__menu');

    if (fixedMenu) {
      const parentMenus = fixedMenu.querySelectorAll('.navbar__link_parent');

      if (parentMenus.length) {
        parentMenus.forEach((parentMenu) => {
          parentMenu.onmouseover = function (e) {
            const submenu = fixedMenu.parentElement.querySelector(':scope > .navbar__submenu');
            if (submenu)
              submenu.remove();

            const childMenu = e.target.parentElement.querySelector('.navbar__submenu');
            const clone = childMenu.cloneNode(true);
            const newSubMenu = fixedMenu.parentElement.appendChild(clone);

            const posX =  parentMenu.parentElement.offsetLeft;
            const scrollLeft = fixedMenu.scrollLeft;

            newSubMenu.style.left = posX - scrollLeft + 'px';
            newSubMenu.classList.add('navbar__submenu_active');
          };

          parentMenu.onmouseout = function (e) {
            const submenu = fixedMenu.parentElement.querySelector(':scope > .navbar__submenu');
            if (!e.relatedTarget.classList.contains('navbar__submenu')) {
              if (submenu)
                submenu.remove();
            }
          };

          fixedMenu.parentElement.onmouseout = function (e) {
            const submenu = fixedMenu.parentElement.querySelector(':scope > .navbar__submenu');
            if (e.target.classList.contains('navbar__submenu') && !e.relatedTarget.classList.contains('navbar__link_child')) {
              if (submenu)
                submenu.remove();
            }
          }
        })
      }
    }
  }

  bindEvents() {
    if (!this.element) {
      return;
    }
    this.element.querySelectorAll(`.${this.classes.toggleButton}`).forEach((e, i, arr) => this.buttonEvent(e, arr));

    this.searchButtons.forEach(button => {
      button.addEventListener('click', e => {
        e.preventDefault();
        const toggleButtons = document.querySelectorAll(`.${this.classes.toggleButton}`);

        if (window.matchMedia('screen and (min-width: 992px)').matches) {
          const toggleButton = document.querySelector(`.${this.classes.toggleButton}`);

          if (!this.shown) {
            toggleButtons.forEach(e => e.classList.toggle('active'));
            this.animate();
          }
        } else {
          this.searchButtons.forEach(el => el.classList.toggle('active'));

          if (this.shown) {
            toggleButtons.forEach(e => e.classList.toggle('active'));
            this.collapsedFixed();
            this.animate();
          }

          this.animateSeach();
        }
      });

      this.showSubMenu();
    });

    const buttonClear = '<a href="#" class="header-collapsed-searchbar__clear"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="14" height="14"><g transform="translate(-6 -6)"><path fill-rule="nonzero" d="M13,14.4142136 L7.87347584,19.5407377 C7.55527778,19.8589358 7.20172439,19.9296465 6.98959236,19.7175144 L6.28248558,19.0104076 C6.07035354,18.7982756 6.14106422,18.4447222 6.45926227,18.1265242 L11.5857864,13 L6.45926227,7.87347584 C6.14106422,7.55527778 6.07035354,7.20172439 6.28248558,6.98959236 L6.98959236,6.28248558 C7.20172439,6.07035354 7.55527778,6.14106422 7.87347584,6.45926227 L13,11.5857864 L18.1265242,6.45926227 C18.4447222,6.14106422 18.7982756,6.07035354 19.0104076,6.28248558 L19.7175144,6.98959236 C19.9296465,7.20172439 19.8589358,7.55527778 19.5407377,7.87347584 L14.4142136,13 L19.5407377,18.1265242 C19.8589358,18.4447222 19.9296465,18.7982756 19.7175144,19.0104076 L19.0104076,19.7175144 C18.7982756,19.9296465 18.4447222,19.8589358 18.1265242,19.5407377 L13,14.4142136 Z"></path></g></svg></a>';

    [...this.element.querySelectorAll(`.${this.classes.searchInput}`)].forEach((item, index, inputs) => {
      item.parentNode.insertAdjacentHTML('beforeend', buttonClear);

      const clears = [...this.element.querySelectorAll('.header-collapsed-searchbar__clear')];

      clears.forEach(clear => {
        clear.addEventListener('click', e => {
          e.preventDefault();
          this.clearInput(inputs, clears);
        });
      });

      item.addEventListener('input', event => {
        const value = event.currentTarget.value;
        const i = (inputs.length - 1) - index;

        if (inputs[i].value !== value) {
          inputs[i].value = value;
        }
      });

      this.requestEvent(item);
    });

    this.searchBar.bindEvents();
    this.bindEventsFixed();


  }

  clearInput(inputs, buttons) {
    const hintWrapper = this.element.querySelector('.search-hint-wrapper');

    if (hintWrapper) {
      hintWrapper.parentNode.removeChild(hintWrapper);
    }
    inputs.forEach(input => input.value = '');
    buttons.forEach(button => button.style.display = 'none');
  }

  removeHintWrapper() {
    if (this.element.querySelectorAll('.search-hint-wrapper').length) {
      const wrappers = this.element.querySelectorAll('.search-hint-wrapper');

      [...wrappers].forEach(hint => {
        hint.parentNode.removeChild(hint);
      });
    }
  }

  requestEvent(input) {
    const inputEvents = ['input', 'paste', 'cut'];
    const clearWrappers = [...this.element.querySelectorAll(`.${this.classes.searchInput}`)];

    inputEvents.forEach(eventName =>
      input.addEventListener(eventName, () => {
        if (input.value.length) {
          const headers = new Headers({'X-Requested-With': 'XMLHttpRequest'});
          let url;

          url = `/api/search/?q=${input.value}`;

          clearWrappers.forEach(item => {
            const clear = item.parentNode.querySelector('.header-collapsed-searchbar__clear');
            clear.style.display = 'block'
          });

          fetch(url, {headers})
            .then(response => response.json())
            .then((data) => {
              if (data.length) {
                const hintWrapper = this.element.querySelector('.search-hint-wrapper') || document.createElement('div');
                const hintWrappers = this.element.querySelectorAll('.search-hint-wrapper');

                if (hintWrappers.length > 1) {
                  this.removeHintWrapper();
                }

                hintWrapper.classList.add('search-hint-wrapper');
                hintWrapper.innerHTML = '';

                data.forEach((element) => {
                  let htmlContent = `<div><a class="content-search__hint" href="${element.url}"><span class="content-search__hint-title">${element.title}</span> <span class="content-search__hint-text">${element.text}</span></a></div>`;

                  hintWrapper.insertAdjacentHTML('beforeend', htmlContent);
                });

                this.element.querySelector(`.${this.classes.searchMobileBar}`).appendChild(hintWrapper);

                hintWrapper.classList.add('_active');
                document.querySelector('.page-header').classList.add('_search-open')
                document.querySelector('.header-collapsed').classList.add('_darkening');

                if (document.querySelector('.page-header__fixed')) {
                  if (!document.querySelector('.page-header__fixed').classList.contains('hidden-lg')) {
                    hintWrapper.style.position = 'fixed';
                    hintWrapper.style.top = `${this.fixedHead.getBoundingClientRect().height}px`;
                    hintWrapper.style.left = `15px`;
                    hintWrapper.style.right = `15px`;
                  }
                }
              }
            });
        } else {
          this.removeHintWrapper();
          clearWrappers.forEach(item => {
            const clear = item.parentNode.querySelector('.header-collapsed-searchbar__clear');
            clear.style.display = 'none';
          });

          document.querySelector('.page-header').classList.remove('_search-open')
        }
      })
    );
  }

  setDrag(element) {
    let drag = false;
    let dragMoved = false;
    let prevX;
    const scrollNav = delta => element.scrollLeft += delta;

    [...element.querySelectorAll('a, img')].forEach(item => item.draggable = false);

    element.addEventListener('wheel', event => scrollNav(event.deltaY));
    element.addEventListener('click', event => {
      if (dragMoved) {
        dragMoved = false;
        event.preventDefault();
        event.stopPropagation();
      }
    }, true);
    element.addEventListener('mousedown', event => {
      drag = true;
      dragMoved = false;
      prevX = event.clientX;
    });
    element.addEventListener('mouseup', event => {
      drag = false;
      event.stopPropagation();
    }, true);
    element.addEventListener('mousemove', event => {
      if (drag) {
        dragMoved = true;
        scrollNav(prevX - event.clientX);
        prevX = event.clientX;
      }
    });

    document.addEventListener('mouseup', () => drag = false);
  }

  bindEventsFixed() {
    this.setDrag(this.fixedHead.querySelector('.header-fixed__menu .navbar'));

    document.addEventListener('scroll', () => this.checkFixedHead());
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const header = new PageHeader({
    baseClass: 'page-header',
  });

  header.bindEvents();

  if (utils.isMobile) {
    const mobileSearchBar = new SearchBar({
      input: document.querySelector('.header-collapsed-searchbar__input'),
      toggleButton: document.querySelector('.header-collapsed-searchbar__icon'),
      timeout: 300,
    });
    mobileSearchBar.bindEvents();
  }
});
